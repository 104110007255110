import Highway from '@dogstudio/highway';
const images = require('../img/erga/*/*.jpg');






var ergo01 = {title: 'Ένα έργο.', desc: 'Ειναι ενα εργο αυτο το εργο οχι το αλλο.' };
var ergo02 = {title: 'Άλλο έργο.', desc: 'Ειναι ενα αλλο εργο αυτο το εργο οχι το ενα.'};












class CustomRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
  	var selida = window.location.href;
  	var shmeio = selida.indexOf("?");
  	var ergo = selida.substring(shmeio + 1);

  	//$('.tsonee-ergoo').addClass(`${ergo}`)
  	$('.tsonee-ergoo').attr("style", `background: linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${images[`${ergo}`].bg}) no-repeat center; background-size: cover`);
  	$('.tsonee-ergo-desc h2').append(eval(ergo).title);
  	$('.tsonee-ergo-desc h3').append(eval(ergo).desc);
  	Object.keys(images[`${ergo}`]).forEach(function (item) {
  		var createImg = '<img src="'+images[`${ergo}`][item]+'"class="mikro"></img>';
		console.log(images[`${ergo}`][item]);
		$('.tsonee-ergo-imgs').append(createImg).clone();
	});

  }
}









// Don`t forget to export your renderer
export default CustomRenderer;