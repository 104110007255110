import Highway from '@dogstudio/highway';
import Tween from 'gsap';
import scrollbar from './tsonee.js';
var xfrom = 0;
var xto = 0;


class Fade extends Highway.Transition {
  in({ from, to, done }) {
    // Reset Scroll
    
	// Remove Old View
    from.remove();
    scrollbar.scroll({ y : "0%"  }, 0, undefined);
    
    if(from.id > to.id){
    	xto = '-100%';
    }else{
    	xto = '100%';
    }
    

    // Animation
    Tween.fromTo(to, 0.2,
    	{

    		x: xto},
    	{        
        x: 0,
        delay: 0.3,
        onComplete: function(){
        	
        	done();
   			}
   		}
    );
  }	

  out({ from, done }) {
    // Animation
    Tween.to(from, 0.2,
      {	
      	opacity: 0,
        onComplete: function(){
        	
        	done();
   			}
      }
    );
  }
}

export default Fade;