import Highway from '@dogstudio/highway';
import Fade from "./tsonee-transitions.js";
import CustomRenderer from "./tsonee-renderers.js";

//routing pipes
var setDefaultActive = function() {
    var path = window.location.pathname;

    var element = $(".tsonee-nav a[href='" + path + "']");

    element.addClass("is-active");
}

setDefaultActive()
var scrollLysh = 125;

var rythmiseis = {
	className : 'os-theme-custom',
	scrollbars : {
		autoHide : 'scroll',
		snapHandle : true
	},
	callbacks : {	
		onScroll : function() { 
			console.log();
			if ($('.tsonee-main-wrapper').attr('id') > 1){
				if (scrollbar.scroll().position.y > scrollLysh){
					if($('#tsonee-header').hasClass('aspro')){

					}
					else{
						$('#tsonee-header').toggleClass('aspro')
						$('#tsonee-header').toggleClass('diafano')
					}
				}
				else{
					if($('#tsonee-header').hasClass('diafano')){

					}
					else{
						$('#tsonee-header').toggleClass('aspro')
						$('#tsonee-header').addClass('diafano')
					}

				}
			}
			else{
				if($('#tsonee-header').hasClass('aspro')){
					$('#tsonee-header').toggleClass('aspro')
					$('#tsonee-header').toggleClass('diafano')
				}		
			}
		}
	}
};
var scrollbar = OverlayScrollbars(document.querySelectorAll("body"), rythmiseis);

const H = new Highway.Core({
	transitions: {
    default: Fade
  },
  	renderers: {
    ergo: CustomRenderer
  }
});
const links = document.querySelectorAll('nav a');

H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
	scrollLysh = 9999;
});

H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
	scrollLysh = 125;
});

H.on('NAVIGATE_IN', ({ to, location }) => {
  // Check Active Link
  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    // Clean class
    link.classList.remove('is-active');

    // Active link
    if (link.href === location.href) {
      link.classList.add('is-active');
    }
  }
  if($('.hamburger').hasClass('is-active')){
  	$('.hamburger').removeClass('is-active')
  	$('.tsonee-nav').removeClass('tsonee-collapse')
  }
});








// edw einai to koumpi gia to collapse
$(".myburger").click(function(){
    $(".hamburger").toggleClass('is-active')
    $(".tsonee-nav").toggleClass('tsonee-collapse')
})
//-----------------------------------------------------


//-----------------------------------------------------

document.addEventListener("DOMContentLoaded", function() {
	//The first argument are the elements to which the plugin shall be initialized
	//The second argument has to be at least a empty object or a object with your desired options
	scrollbar;
});
//-----------------------------------------------------



export default scrollbar;